<template>
    <div class="fzls">
        <myhead :param="param" />
        <section>
            <div class="bannerafzls">
                <div style="height:280px; "></div>
                <div style="font-size: 44px;font-weight: bold;color: #ffffff;">发展历程</div>
                <!--<div class="htable" style="font-size: 18px; color: rgba(255,255,255,0.8);margin-top: 40px;line-height: 32px; justify-content: center;">
                    <div class="hcell" style="width: 1200px;">
                    重庆辉腾能源股份有限公司（证券简称：辉腾能源，证券代码：872947）着眼全球清洁能源可持续发展，专注低日照弱光环境智能光伏
                    设备研发生产，提供从光伏产品到光伏应用的整体解决方案，不断开拓创新，引领光伏产业进步，用太阳能造福全人类。
                        </div>
                </div>
                <div class="htable" style="font-size: 18px; color: rgba(255,255,255,0.8);margin-top: 40px; justify-content: center;line-height: 32px;">
                    <span class="hcell" style="border: 1px solid rgba(255,255,255,0.8);padding: 20px;">
                        铸平台共创价值 用科技改变生活
                    </span>
                </div>-->
            </div>
        </section>
        <section style="margin-top: 90px;">
            <div class="body1660 htable" style="align-items: center;">
                <div class="hcell" style="width: 130px;text-align: left;display: flex;">
                        <div class="arleft" @click="getcrows(1)"></div>
                </div>
                <div class="hcell" style="width: 1400px;background-color: #eeeeee;">
                    <ul class="fzlsul" style="text-align: left;font-size: 18px;color: rgba(0,0,0,0.8);line-height: 38px;">
                        <li v-for="(n,i) of crows.length" :key="'item'+crows[i].tb_id">{{crows[i].tb_title}} </li>
                        <!--<li>获得重庆市著名商标荣誉称号。</li>
                        <li>取得GB/T28001-2011/OHSAS18001:2007职业健康管理体系认证。</li>
                        <li>获得重庆市科技进步三等奖。</li>
                        <li>获得高新区2014年度优秀科技型企业荣誉称号。</li>
                        <li>由我公司设计、开发、生产和安装的全市第一条纯太阳能路灯市政道路在高新区创新大道正式亮灯,多家电视、网站等媒体进行了报道。</li>
                        <li>在重庆成立分公司,名为“重庆万图科技有限公司”。</li>
                        <li>在重庆率先建立了第一批光伏扶贫电站，将为巫溪县600多户贫困户家庭每年增收200多万，入选国务院扶贫办确定的十大精准扶贫工程之一。</li>
                        <li>公司研发成果“低日照地区高效太阳能照明系统研发及应用”获得科学技术成果登记证书。</li>
                        <li>太阳能路灯、太阳能庭院灯、太阳能杀虫灯获得重庆市高新技术产品认定。</li>
                        <li>获得高新技术企业复审认定。</li>-->
                    </ul>
                </div>
                <div class="hcell" style="width: 130px;text-align: right;display: flex;flex-direction: row-reverse">
                    <div class="arright" @click="getcrows(-1)"></div>
                </div>
            </div>
        </section>
        <section style="margin-top: 60px;">
            <div class="body1660 htable">
                <div class="hcell" style="width: 130px; "></div>
                <div class="hcell" style="width: 1400px;">
                    <div>
                        <div class="htable" style="width: 1400px;">
                            <div class="hcell" style="width: 35px" > </div>
                            <div style="width: 1330px;background-color: #4d4d4d ; height: 2px;"></div>
                            <div class="hcell" style="width: 35px" > </div>
                        </div>

                        <div class="htable" style="justify-content: space-between;align-items: center;margin-top: -36px;flex-direction:row-reverse;">
                            <div :class="years[cyear]==yy?'hcell ybc':'hcell yb'"  v-for="yy in years " :key="yy"  @click="gotoYear(yy)"  >
                                <div :class="years[cyear]==yy?'yearlsc':'yearls'"  :key="yy"   >{{yy}}年</div>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="hcell" style="width: 130px; "></div>
            </div>

        </section>
        <section style="height: 80px;">
        </section>
    </div>
</template>
<script>
    import myhead  from  '../components/myhead.vue'
    import { listTopicBase } from "../hts/api.js"
    const param = {
        pageindex: 5,
        rows: 6,
        page: 1,
    }
    export default {
        name: "",
        components: {
            myhead,
        },
        data() {
            return {
                param,
                rows:[],
                years:[],
                yearmax:3000,
                yearmin :0 ,
                crows:[],
                cyear:0,
                yb:require('../assets/yb.png'),
                yrd:require('../assets/yrd.png'),
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData () {
                listTopicBase({fbId: '24', page: this.page })
                    .then(resData => {
                        this.rows=resData.data.rows
                        var _cy=""
                        var _ys=""
                        for (var i =0;i< this.rows.length;i++) {
                            //console.log(i)
                            let _vt=this.rows[i].tb_create_time
                            _ys= (""+ _vt).substring(0,4)
                            if (_cy!==_ys){
                                this.years.push(_ys)
                                _cy=_ys
                            }
                        }
                        this.cyear=0
                        this.yearmax = this.years.length-1
                        this.yearmin=0
                        this.getcrows(0)
                    })
                    .catch()
            },
            getcrows(addy){

                var year=parseInt(this.cyear)+parseInt(addy)
               // console.log("1:"+year)
               // console.log("1:yearmax>"+this.yearmax)
               // console.log("1:yearmin>"+this.yearmin)
                if(year>this.yearmax || year<this.yearmin){
                    return
                }
                this.gotoYear(this.years[year])
            },
            gotoYear(year){
               // console.log("2:"+year)
                this.crows=this.rows.filter(function (item) {
                    let _vt=item.tb_create_time
                    var _ys= (""+ _vt).substring(0,4)
                    if (year==_ys) {
                        return item
                    }
                })

                for(var i=0;i<this.years.length;i++){
                    if (this.years[i]===year){
                        this.cyear=i;
                        break
                    }
                }
               // console.log("3:"+this.cyear)
            },
        },
    }
</script>
<style lang="css" scoped>
    .fzls {
        width: 100%;
    }
    .bannerafzls{
        background-image: url('~assets/fzls.png');
        height: 600px;
    }
    .arleft{
        width: 40px;
        height: 40px;
        background-image: url('~assets/arlefcib.png');
    }
    .arleft:hover{
        /*background-image: url('~assets/arlefcir.png');*/
        cursor:pointer;
    }

    .arright{
        width: 40px;
        height: 40px;
        background-image: url('~assets/arrigcib.png');
    }
    .arright:hover{
        /*background-image: url('~assets/arrigcir.png');*/
        cursor:pointer;
    }
    .fzlsul li{
        list-style-type: disc ;

    }
    .fzlsul li::marker {
        color: #d7000f;
    }
    .yb{
        /*border: 1px solid #d7000f;*/
        width: 72px;
        height: 72px;
        background-image: url('~assets/yb.png');
        background-repeat: no-repeat;
        background-position: 25px 25px;
        font-size: 18px;
        /*padding-top: 55px;*/
    }
    .yb:hover{
        /*border: 1px solid #d7000f;*/
        background-position: 20px 20px;
        background-image: url('~assets/yrd.png');
        color:  #d7000f;
        cursor:pointer;
    }

    .ybc{
        width: 72px;
        height: 72px;

        background-repeat: no-repeat;

        font-size: 18px;

        background-position: 20px 20px;
        background-image: url('~assets/yrd.png');
        color:  #d7000f;
        cursor:pointer;
    }
    .yearls{
        margin-top: 55px;
    }
    .yearlsc{
        margin-top: -10px;
    }
</style>
